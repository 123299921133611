<template lang="pug">
.checkout-order-summary
  .title Ваш заказ
  .content
    .info
      .info-caption {{ totalLabel }}
      .info-value {{ totalPrice | formatPrice }}
      .info-caption Доставка:
      .info-value(v-if="hiddenPoint && hiddenPoint.isPayed") 200 ₽
      .info-value(v-else-if="deliveryPrice") {{ deliveryPrice | formatPrice }}
      .info-value(v-else-if="deliveryPrice === 0") бесплатно
      .info-value(v-else-if='deliveryType === DeliveryType.COURIER')
      .info-value(v-else) не выбрана
      template(v-if="discountAmount")
        .info-caption Применен промокод:
        .info-value {{ discountAmount | formatPrice }}

    .summary
      .summary-caption(v-if="hiddenPoint && hiddenPoint.isPayed") Итого без доставки:
      .summary-caption(v-else) Итого:
      .summary-value {{ priceWithDiscount | formatPrice }}
    max-weight-plate(v-if="isShowMaxWeightLimit")
    payed-delivery-plate(v-if="hiddenPoint && hiddenPoint.isPayed")
    transition(name="fade")
      .loading(v-if="isLoadingExpected")
        spinner-ds(:size="18")

  promo-code-ds(:isLoading="isPromoLoad")

  .actions
    button-ui.proceed(:disabled="!canProceed" @click.native.stop="tryProceed") Подтвердить и оплатить
      spinner-ds(v-if="isLoadingComplete" :size="18" :stroke="1" slot="before")

  .note Размещая заказ, вы соглашаетесь на обработку персональных данных в соответствии с #[link-ui(:to="{name:'termsofuse'}" style="text-decoration: underline") Соглашением о конфиденциальности и Пользовательским соглашением] {{ static.brandShort }}
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PromoCodeDs from './promo-code';
import ButtonUi from '~/components/common/button';
import MaxWeightPlate from '~/components/max-weight-plate';
import PayedDeliveryPlate from '~/components/payed-delivery-plate.vue';
import { DeliveryType } from '~/modules/checkout/types';
import SpinnerDs from '~/components/spinner';
import { CanCheckoutMixin } from '~/mixins/business/checkout';
import { StaticDataMixin } from '~/mixins/staticData';
import { currencyFormatMixin } from '~/mixins';
import { PopupsContainerModel } from '~/modules/popups/models';
import { PopupsService } from '~/modules/popups/services/popups-service';
import PayedNotifyPopup from '~/components/popups/payed-notify-popup.vue';
import LinkUi from '~/components/common/link';
export default {
  components: {
    ButtonUi,
    PromoCodeDs,
    SpinnerDs,
    MaxWeightPlate,
    PayedDeliveryPlate,
    PayedNotifyPopup,
    LinkUi,
  },
  mixins: [CanCheckoutMixin, currencyFormatMixin, StaticDataMixin],
  data: () => ({
    DeliveryType,
  }),
  computed: {
    ...mapState('checkout', [
      'deliveryPrice',
      'deliveryType',
      'products',
      'isLoadingExpected',
      'isLoadingComplete',
      'recipient',
      'city',
      'discountAmount',
      'isPromoLoad',
      'comment',
      'hiddenPoint',
    ]),
    ...mapGetters('checkout', ['hasMaxWeightLimit', 'hasNonWeightProducts']),
    totalPrice() {
      return this.products.map((_) => _.sku.sellPrice * _.amount).reduce((acc, cur) => acc + cur, 0);
    },
    totalAmount() {
      return this.products.map((_) => _.amount).reduce((acc, cur) => acc + cur, 0);
    },
    totalLabel() {
      return `${this.totalAmount <= 1 ? 'Товар' : 'Товары'} (${this.totalAmount}):`;
    },
    isCommentValidate() {
      if (this.deliveryType === DeliveryType.DELIVERY_POINT) {
        return this.comment && this.comment.length > 0 ? true : this.hiddenPoint && this.hiddenPoint.id ? true : false;
      }

      return true;
    },
    isShowMaxWeightLimit() {
      return this.deliveryType === DeliveryType.RUSSIAN_POST && this.hasMaxWeightLimit;
    },
    hasRussianPostLimit() {
      return this.deliveryType === DeliveryType.RUSSIAN_POST && (this.hasMaxWeightLimit || this.hasNonWeightProducts);
    },
    isValidMaxLength() {
      return (
        this.recipient.email.length <= 200 &&
        this.recipient.firstName.length <= 200 &&
        this.recipient.lastName.length <= 200
      );
    },
    priceWithDiscount() {
      const sum = this.totalPrice + (this.deliveryPrice || 0) + (this.discountAmount || 0);
      if (sum > 0) {
        return sum;
      } else {
        return 0;
      }
    },
    fullUserFields() {
      return this.recipient.email.length > 0 && this.recipient.phone.length > 0;
    },
    canProceed() {
      return (
        this.canCheckout &&
        this.isValidMaxLength &&
        !this.isLoadingExpected &&
        !this.isLoadingComplete &&
        !this.hasRussianPostLimit &&
        (this.deliveryPrice || this.deliveryPrice === 0) &&
        this.isCommentValidate &&
        this.fullUserFields
      );
    },
  },
  watch: {
    canProceed(value) {
      if (value !== false && !window.dataLayer.find((obj) => obj.event == 'add_shipping_info')) {
        this.$gtm.push({ ecommerce: null });
        this.$gtm.push(
          this.$dataLayer.addShippingEvent(
            this.products,
            this.totalPrice,
            this.deliveryType,
            this.city.city,
            this.$route.query.productId ? 'Купить в 1 клик' : 'Купить из корзины',
          ),
        );
      }
    },
  },
  methods: {
    tryProceed() {
      if (!this.canProceed) {
        this.$store.dispatch('checkout/updateShowWarnings', true);
        this.$store.dispatch('checkout/updateWarningCount');
        return;
      }

      if (this.hiddenPoint && this.hiddenPoint.isPayed) {
        PopupsService.open({
          type: PopupsContainerModel.ETypeWrapper.CENTER,
          component: PayedNotifyPopup,
        });
        return;
      }

      this.$store.dispatch('checkout/updateShowWarnings', false);
      this.$root.$emit('checkout:complete-order');
    },
  },
};
</script>

<style lang="stylus">
.fade-enter-active
.fade-leave-active
  transition opacity .5s

.fade-enter
.fade-leave-to
  opacity 0

.checkout-order-summary
  +Media(Laptop, Desktop, DesktopMax)
    padding 24px
    border 1px solid Divider()
  +Media(Mobile, Tablet)
    padding 16px 0

  .title
    Text(TitleM Short Secondary)
    text-align center
    +Media(Mobile, Tablet)
      text-align left

  InfoGrid()
    display grid
    grid-template-columns 1fr auto
    grid-row-gap 8px
    align-items center
    &-value
      justify-self end
  .content
    position relative
    margin-top 16px
    .info
      Text(BodyM Short)
      InfoGrid()
      color TextPrimary()
    .summary
      InfoGrid()
      margin-top 16px
      .summary-caption
        Text(BodyM Semibold Short)
      .summary-value
        Text(BodyL Semibold Short)
    .loading
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      background White(A80)
      display flex
      align-items center
      justify-content center

  .ds-promo-code
    margin-top 16px

  .actions
    .proceed
      Button(Primary Large)
      Text(BodyL Semibold)
      width 100%
      margin-top 16px
      .ds-spinner
        margin-right 10px

  .note
    Text(CaptionM Long Light)
    margin-top 16px
    color TextSecondary()
</style>
