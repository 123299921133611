<template lang="pug">
.ds-breadcrumbs(itemscope itemtype="https://schema.org/BreadcrumbList")
  .item(v-for="(item, i) in items" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem")
    link-ui(v-if="item.url!=='#'" :to="item.url" itemprop="item") {{ item.text }}
    .ui-link.disabled(v-else itemprop="item") {{ item.text }}
    .separator(v-if="i < (items.length - 1)" itemprop="separator") {{ separator }}
    meta(itemprop="position" :content="i + 1")
</template>

<script>
import LinkUi from '~/components/common/link';

export default {
  components: {
    LinkUi,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    separator: {
      type: String,
      default: '/',
    },
  },
};
</script>

<style lang="stylus">
.ds-breadcrumbs
  .item
    display inline-block

    .separator
      Text(CaptionM Light)
      color TextTertiary()
      margin-left 8px
      margin-right 8px

    .ui-link
      Text(CaptionM Medium)
      color TextPrimary()
      padding 4px
      &.disabled
        Text(CaptionM Light)
        cursor default

    & > *
      display inline-block
</style>
