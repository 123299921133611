export const basicToken =
  process.env.NUXT_ENV_GQL_TOKEN ||
  (process.env.NODE_ENV !== 'development' ? 'cHNiX3Nob3A6cHNiX3Nob3Bfc2VjcmV0' : 'cHNiX3Nob3A6ZGVkX3Nob3Bfc2VjcmV0');
export const daDataToken = 'da64de1422eccdca35d8598dc52dd6340964dd59';

export const PROD_URL = 'https://dshop.xn--80abntiqkep.xn--p1ai/';
export const DEV_URL = 'https://graphql-dshop.dev.xn----8sbbsyksmer.xn--p1ai';

export const LOUIS_API_HOST = 'https://louis.dev.cluster.kznexpess.com';
export const LOUIS_KEY =
  'ZG7rqGpD1t2XFamS7mhK4Wi1maDQAD6Y0wi7wDHg6PUNGDnQnSs657MWompaTfdkFRxwDiASjcvfuV3CVP0Sv4n3bBHcJuavTxqBBonukFeBktFOzfA0ivao70znfbKZeup9NtI3MSsTeVJLjEi6EYW15PSXmKF3szGi409REXvNlIxRzwYTCT76j17mb2wocvfZLEKn';

export const MINDBOX_ENDPOINT_ID =
  process.env.NUXT_ENV_MB_ENDPOINT ||
  (process.env.NODE_ENV !== 'development' ? 'Psbmarket.Website' : 'Psbmarket.Website.test');

export const CLICKSTREAM_URL =
  process.env.NUXT_ENV_CS_URL ||
  (process.env.NODE_ENV !== 'development'
    ? 'https://el.xn--80abntiqkep.xn--p1ai/events' // https://el.псбмаркет.рф
    : 'https://clickstream-psb.dev.cluster.kznexpess.com/events');

export const SENTRY_DSN = 'https://709ae77794d83e30adc5a701108e4cd5@sentry.xn----8sbbsyksmer.xn--p1ai/3';
export const SENTRY_ENVIRONMENT = process.env.NODE_ENV !== 'development' ? 'development' : 'production';

export const MAX_WEIGHT_RUSSIAN_POST = 19000; // in grams

export const dshopName = 'psb-dshop';
export const HOST = 'https://xn--80abntiqkep.xn--p1ai'; // 'https://псбмаркет.рф'
export const columnSeparator = '<meta class="divider"></meta>';

const preconnectHrefs = [
  'https://ke-images.servicecdn.ru',
  'https://fonts.gstatic.com',
  'https://fonts.googleapis.com',
  PROD_URL,
];

export const preconnectLinks = [
  ...preconnectHrefs.map((href) => ({
    rel: 'preconnect',
    href,
  })),
  ...preconnectHrefs.map((href) => ({
    rel: 'dns-prefetch',
    href,
  })),
];

export const preloadLinks = [
  'https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2',
  'https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2',
  'https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2',
].map((href) => ({
  rel: 'preload',
  href,
  as: 'font',
  type: 'font/woff2',
  crossorigin: true,
}));

export const titles = {
  default: 'Интернет-магазин ПСБ Маркет – большой выбор товаров с доставкой в ЛНР, ДНР, Херсон, Запорожье',
};

export const descriptions = {
  default:
    'ПСБ Маркет – официальный маркетплейс на территории ЛНР, ДНР, Херсонской и Запорожской области. Скидки, акции и промокоды для новых и постоянных клиентов',
};

const getDescriptionString = (skuGroups) => {
  let titleDecription = '';
  const descriptions = [];
  skuGroups.forEach(({ characteristicValues }) => {
    characteristicValues.forEach(({ characteristic, title }) => {
      descriptions.push(title);
      titleDecription = characteristic.title;
    });
  });
  return skuGroups.length > 1 ? ` ${titleDecription}: ${descriptions}` : '';
};

export const staticData = {
  links: {
    privacy: 'https://legal.kazanexpress.ru/privacy-policy.html',
    socials: [
      {
        link: 'https://t.me/psb_market',
        icon: 'social/telegram',
      },
      {
        link: 'https://vk.com/psbmarket',
        icon: 'social/vk',
      },
      {
        link: 'https://t.me/psbmarket_official_bot?start=restart-ynsi0J8g_footer',
        icon: 'social/support',
      },
    ],
  },
  support: {
    email: '',
    prEmail: '',
    partnersEmail: '',
    phone: '88007009616',
    phoneFormat: '# ### ### ## ##',
    chat: 'https://t.me/PSBMarket_Bot',
    nameChat: '@PSBMarket_Bot',
    time: {
      from: '9:00',
      to: '21:00',
    },
    holidayTime: {
      from: '10:00',
      to: '22:00',
    },
  },
  DescCatalogList: [],
  customCities: [],
  contacts: {
    inn: 9722025064,
    kpp: 772201001,
    ogrn: 1227700406955,
  },
  brandShort: 'ПСБ Маркет',
  ownDpName: 'ПСБ Маркет',
  brandFull: 'Общество с ограниченной ответственностью "ПСБ МАРКЕТ"',
  brand: 'ООО "ПСБ МАРКЕТ"',
  yearRights: new Date().getFullYear(),
  subheader:
    '<div>Покупайте с бесплатной доставкой за 1 день. <nuxt-link to="/faq#get-method">Условия доставки</nuxt-link></div>',
  description: '',
  profits: [
    {
      icon: 'navigation/loyalty-program_line',
      subtitle: 'Экономия 90%, оплачивая бонусными баллами ПСБ',
    },
    {
      icon: 'status/pickpoint_line',
      subtitle: 'Доставка по всей РФ (удобная и быстрая)',
    },
    {
      icon: 'status/quality_line',
      subtitle: 'Рассрочки и льготное кредитование от банка ПСБ',
    },
    {
      icon: 'status/secure-payment_line',
      subtitle: 'Дополнительные скидки с картой банка ПСБ',
    },
  ],
  checkoutFias: [],
  isShowKazanExpressTechnologies: false,
  isShowDiscountProductBadge: true,
  isApplyOldSearch: false,
  isShowSEOText: false,
  isShowProfits: false,
  isShowBadges: false,
  isShowReviews: false,
  isShowOrderComment: true,
};

export const SEO_DATA_PAGES = {
  SEARCH: 'SEARCH',
  PRODUCT: 'PRODUCT',
  OFFER: 'OFFER',
  CATALOG: 'CATALOG',
  ALL: 'ALL',
};

const buildSeoData = ({ title, meta }, path) => ({
  title,
  meta: Object.entries(meta).map(([name, content]) => ({
    hid: name,
    name,
    content,
  })),
  link: [
    {
      rel: 'canonical',
      href: `${HOST}${path}`,
    },
  ],
});

const addLink = (path, links) =>
  links
    ? {
      link: [
        {
          rel: 'canonical',
          href: `${HOST}${path}`,
        },
        ...links,
      ],
    }
    : {
      link: [
        {
          rel: 'canonical',
          href: `${HOST}${path}`,
        },
      ],
    };

export const seoData = {
  [SEO_DATA_PAGES.SEARCH]: (path) => {
    const title = 'ПСБ Маркет — интернет-магазин от Промсвязьбанка';
    const description =
      'Повседневные товары, электроника и тысячи других товаров по выгодным ценам от одного из крупнейших банков России';

    return buildSeoData(
      {
        title,
        meta: {
          description,
          keywords:
            'маркетплейс, интернет-магазин, каталог товаров, низкие цены, выгодные цены, ПСБ, ПСБ маркет, Промсвязьбанк',
          'og:title': title,
          'og:description': description,
          'og:image': `${HOST}/images/logo.png`,
          'twitter:title': title,
          'twitter:description': description,
          'twitter:image': `${HOST}/images/logo.png`,
        },
      },
      path,
    );
  },
  [SEO_DATA_PAGES.PRODUCT]: (value = {}, path) =>
    buildSeoData(
      {
        title: `${value.title} за ${value.price} ₽ купить c бесплатной доставкой в ЛНР, ДНР, Херсон, Запорожье интернет-магазине ПСБ Маркет`,
        meta: {
          description: `Купить ${value.title}${getDescriptionString(
            value.skuGroups,
          )}: характеристики, описание, фото. Бесплатная доставка в Луганск, Донецк, Запорожскую и Херсонскую области`,
          keywords: `${value.title}, купить, маркетплейс, скидки, интернет-магазин, каталог товаров, низкие цены, ПСБ, ПСБ маркет, Промсвязьбанк`,
          'og:url': `${HOST}${path}`,
          'og:title': value.title,
          'og:description': value.shortDescription,
          'og:image': value.photos?.[0].original.high,
          'twitter:title': value.title,
          'twitter:description': value.shortDescription,
          'twitter:image': value.photos?.[0].original.high,
        },
      },
      path,
    ),
  [SEO_DATA_PAGES.OFFER]: (value = {}, path, query) => {
    let meta = {
      description: `${value.title} в интернет-магазине ПСБ Маркет. Покупайте с доставкой в Донецке, Луганске, Херсоне и Запорожье`,
      keywords:
        'эксклюзивы, маркетплейс, интернет-магазин, каталог товаров, низкие цены, ПСБ, банк ПСБ, ПСБ Маркет, Промсвязьбанк',
    };

    if (query >= 2) {
      meta = { ...meta, robots: 'noindex, follow' };
    } else {
      meta = { ...meta, robots: 'index, follow' };
    }

    return buildSeoData(
      {
        title: `${value.title} купить в ЛНР, ДНР, Херсоне, Запорожье — интернет-магазин ПСБ Маркет`,
        meta,
      },
      path,
    );
  },
  [SEO_DATA_PAGES.CATALOG]: (value = {}, path, query) => {
    const title = `${value.title} купить в ЛНР, ДНР, Херсоне, Запорожье — интернет-магазин ПСБ Маркет`;
    const description = `${value.title} в интернет-магазине ПСБ Маркет. Покупайте с доставкой в Донецке, Луганске, Херсоне и Запорожье`;

    let meta = {
      title,
      description,
      keywords: `${value.title}, маркетплейс, интернет-магазин, каталог товаров, низкие цены, выгодные цены, ПСБ, ПСБ Маркет, Промсвязьбанк`,
      'og:title': title,
      'og:description': description,
      'og:image': `${HOST}/images/logo.png`,
      'twitter:title': title,
      'twitter:description': description,
      'twitter:image': `${HOST}/images/logo.png`,
    };

    if (query >= 2) {
      meta = { ...meta, robots: 'noindex, follow' };
    } else {
      meta = { ...meta, robots: 'index, follow' };
    }

    return buildSeoData(
      {
        title: value.title ? `Купить ${title} в интернет-магазине от Промсвязьбанка` : title,
        meta,
      },
      path,
    );
  },
  [SEO_DATA_PAGES.ALL]: (path) => {
    return addLink(path);
  },
};
